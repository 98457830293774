import { AdomsOrderFlightJunctionDTO } from "./AdomsOrderFlightJunctionDTO";
import { UserInfo } from "./UserInfomation";
/**
 * 操作履歴
 */
export interface OperationHistory {
    /**
     * イベントID
     */
    eventId: string,
    /**
     * シーケンス番号
     */
    sequenceNumber: string,
    /**
     * テーブル名
     */
    tableName: string,
    /**
     * イベント名（INSERT/MODIFY/REMOVE）
     */
    eventName: EventName,
    /**
     * 変更されたカラム
     */
    modifiedColumn?: string[],
    /**
     * フライト日
     */
    flightDate?: string,
    /**
     * 更新日時
     */
    updateDate: string,
    /**
     * 更新日時
     */
    updateDateTime: string,
    /**
     * 更新したユーザー(eventNameがINSERTかMODIFYの時だけ設定される)
     */
    updateUserInfo?: UserInfo,
    /**
     * ID
     */
    id: string,
    /**
     * サブID
     */
    subId: string,
    /**
     * フライト情報、配送情報
     */
    oldItem?: AdomsOrderFlightJunctionDTO
    /**
     * フライト情報、配送情報
     */
    newItem?: AdomsOrderFlightJunctionDTO,
    /**
     * ビジネスパートナーID
     */
    businessPartnerId?: string
}

/**
 * info
 */
export const Info = {
    AssosiateFlightAndOrder: "AssosiateFlightAndOrder",
    FlightInfo: "FlightInfo",
    OrderInfo: "OrderInfo"
} as const;
export type Info = typeof Info[keyof typeof Info];

/**
 * イベント名
 */
export const EventName = {
    INSERT: "INSERT",
    MODIFY: "MODIFY",
    REMOVE: "REMOVE"
} as const;
export type EventName = typeof EventName[keyof typeof EventName];

/**
 * レコードタイプ
 */
export const RecordType = {
    Flight: "Flight",
    Order: "Order",
    FOJunction: "FOJunction" // ジャンクションレコード
} as const;
export type RecordType = typeof RecordType[keyof typeof RecordType];


/**
 * レコードタイプ
 */
export class OperationHistoryUtil {
    public static getRecordType(operationHistory: OperationHistory) {
        let recordType: RecordType;
        if (operationHistory.id.startsWith("flight-") && operationHistory.subId.startsWith("flight-")) {
            return RecordType.Flight;
        } else if (operationHistory.id.startsWith("flight-") && operationHistory.subId.startsWith("order-")) {
            return RecordType.FOJunction;
        } else if (operationHistory.id.startsWith("order-") && operationHistory.subId.startsWith("order-")) {
            return RecordType.Order;
        } else {
            throw new Error("Invalid record type.");
        }
    }
}

/**
 * タイムライン
 */
export const TimelineStatus = {
    Empty: "Empty",
    Order_NewOrder: "NewOrder",
    Order_AddOrder: "AddOrder",
    Order_RemoveOrder: "RemoveOrder",
    Order_Received: "Received",
    Order_Loaded: "Loaded",
    Order_Preparation: "Preparation",
    Order_WaitingForPickup: "WaitingForPickup",
    Order_DepartForPort: "DepartForPort",
    Order_Cancel: "Cancel",
    Flight_NewFlight: "NewFlight",
    Flight_Standby: "Standby",
    Flight_BeforePreparation: "BeforePreparation",
    Flight_Preparation: "Preparation",
    Flight_BeforeTakeoff: "BeforeTakeoff",
    Flight_Takeoff: "Takeoff",
    Flight_InFlightClimb: "InFlightClimb",
    Flight_InFlightCruise: "InFlightCruise",
    Flight_InFlightDescent: "InFlightDescent",
    Flight_AfterLanding: "AfterLanding",
    Flight_Completed: "Completed",
    Flight_Reset: "Reset",
    Flight_Irregular: "Irregular",
    Flight_Canceled: "Canceled",
    Other: "Other"
} as const;
export type TimelineStatus = typeof TimelineStatus[keyof typeof TimelineStatus];
